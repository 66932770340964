import { FC, ReactNode, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { spacing } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';

import {
  getProductCardContent,
  removeLocalMerchantSession,
} from './ProductSelectionService';

import { Grid } from 'shared/components/Grid';
import { Meta } from 'shared/components/Meta';
import { ProductGallery } from 'productSelection/containers/ProductGallery';
import { Cart } from 'shared/components/Cart';
import {
  IMetadataContentFields,
  IFooterFields,
  IUiNotificationsFields,
  IUiProductSelectionPageFields,
  IColumnLayout,
} from 'shared/infra/contentful/contentful';
import { ICartContent } from 'shared/types/cart';
import {
  IDigitalProductDetails,
  IProductDetails,
} from 'productSelection/types/products';
import { resetOrder } from 'shared/store/order/actions';
import { useShopExperience } from 'shared/context';
import { ShopExperience } from 'shared/constants/ShopExperience';
import { Footer } from 'shared/components/Footer';
import { ICountryWithLocale } from 'shared/components/LocaleDropdown/LocaleDropdown';
import {
  useNotifications,
  withNotifications,
} from 'shared/components/NotificationProvider';
import { removeLocalOrderInformation } from 'shared/services/OrderInformationService';
import { resetPromotions } from 'shared/store/promotions/actions';
import {
  getAttributes,
  getUserId,
  optimizely,
} from 'shared/services/optimizely';
import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';
import { usePromotionPRCs } from 'productSelection/hooks/usePromotionPRCs';
import { ISkuPriceData } from 'shared/infra/storefront/markets/prices';
import usePromotions from 'shared/hooks/promotions/usePromotions';
import {
  PS_CTA_TO_CONTEXTUALIZED_SSO,
  TRANSPARENT_FEE,
} from 'shared/services/optimizely/constants';
import useFeatureToggleCookie from 'shared/hooks/helpers/useFeatureToggleCookie';
import { useTypedSelector } from 'shared/store';
import useLoadLocalOrder from 'shared/hooks/orders/useLoadLocalOrder';
import { Section } from 'productSelection/containers/Section';
import { ProductSelectionPageTitle } from 'productSelection/components/PageTitle';
import { BlackHeaderBar } from 'shared/components/BlackHeaderBar';

const StyledGrid: FC<{ children: ReactNode }> = styled(Grid)(
  ({ theme }) => css`
    ${theme.mq.untilKilo} {
      padding: 0 ${theme.spacings.mega};
    }
  `,
);

// The media query values below are based on investigation of where the footer
// breaks away from the bottom of the screen on the product selection page
// or covers other content making it inaccessible.
const WIDTH_BREAKPOINT_FOR_FOOTER_STYLE = 1600;
const HEIGHT_BREAKPOINT_FOR_FOOTER_STYLE = 900;
const DEVICE_WIDTH_BREAKPOINT = 812;

function getHeightBreakpointForFooterStyle(isDefault = true) {
  if (isDefault) {
    return HEIGHT_BREAKPOINT_FOR_FOOTER_STYLE;
  }

  return HEIGHT_BREAKPOINT_FOR_FOOTER_STYLE + 150;
}

const TransparentFeesAndFooter = styled.div<{
  productsOnOneLine?: boolean;
  isGridExperiment?: boolean;
  hasProductsInCart?: boolean;
}>(
  ({
    productsOnOneLine = false,
    isGridExperiment = false,
    hasProductsInCart = false,
  }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    width: 100%;

    ${productsOnOneLine &&
    !hasProductsInCart &&
    `
    @media (min-width: ${WIDTH_BREAKPOINT_FOR_FOOTER_STYLE}px) {
      position: fixed;
    }
    `}

    ${productsOnOneLine &&
    `
    @media screen and (min-height: ${getHeightBreakpointForFooterStyle(
      isGridExperiment,
    )}px) and (min-device-width: ${DEVICE_WIDTH_BREAKPOINT}px) {
      position: fixed;
    }
    `}
  `,
);

export interface IProductSelectionProps {
  products?: IProductDetails[];
  digitalProducts: IDigitalProductDetails[];
  cartContent?: ICartContent;
  prices?: Record<string, ISkuPriceData>;
  pageContent?: IUiProductSelectionPageFields & {
    productBuyNowText?: string;
    addToCartText?: string;
  };
  notificationsContent?: IUiNotificationsFields;
  countries?: ICountryWithLocale[];
  marketReference: string;
  commerceLayerClient: string;
  metaReference: IMetadataContentFields;
  footerContent: IFooterFields;
  isCtaToCheckoutEnabled?: boolean;
  orderOverviewTitle?: string;
  isDiscountPriceEnabled?: boolean;
  isBusinessAccountBundleEnabled?: boolean;
  taxMessage: string;
  sections?: IColumnLayout[];
  defaultTaxRate: number;
}

export const ProductSelectionComponent: FC<IProductSelectionProps> = ({
  products,
  digitalProducts,
  prices,
  cartContent,
  pageContent,
  notificationsContent: { removeOutOfStockProductMessage },
  countries = [],
  marketReference,
  metaReference,
  footerContent,
  isCtaToCheckoutEnabled,
  orderOverviewTitle,
  taxMessage,
  isDiscountPriceEnabled = true,
  isBusinessAccountBundleEnabled = false,
  sections,
  defaultTaxRate,
}) => {
  const { locale, replace, pathname, query } = useRouter();
  usePromotions({
    locale,
    marketReference,
  });
  const dispatch = useDispatch();
  const loadLocalOrder = useLoadLocalOrder();

  const productCardContent = getProductCardContent(pageContent);
  const shopExperience = useShopExperience();
  const isDashboardShop = shopExperience === ShopExperience.DASHBOARD;
  const {
    products: productsInCart,
    operationStatus,
    isBusinessAccountProposed,
  } = useTypedSelector((state) => state.order);
  const hasProductsInCart = productsInCart.length > 0;
  const { displayNotification } = useNotifications();

  const isPsCtaToSsoEnabled = useFeatureToggleCookie(
    PS_CTA_TO_CONTEXTUALIZED_SSO,
    false,
  );

  const handleLocaleChange = useCallback(
    async (code: string) => {
      await replace({ pathname, query }, undefined, { locale: code });

      const userId = getUserId();
      const newCountryCode = getCountryCodeFromISOLocale(code);
      const attributes = getAttributes(newCountryCode);

      optimizely.setUser({ id: userId, attributes });
    },
    [replace, pathname, query],
  );

  const displayCountrySelectionNotification = useCallback(() => {
    displayNotification({
      title: pageContent.updateProductsNotificationTitle,
      message: pageContent.updateProductsNotificationDescription,
      variant: 'confirm',
      sendToTop: true,
    });
  }, [displayNotification, pageContent]);

  /**
   * To clear session storage from merchant-related information.
   * Enabling merchants to go back to product-selection page and
   * order a reader from a different locale
   */

  useEffect(() => {
    if (query.switched_country) {
      displayCountrySelectionNotification();
      return;
    }

    if (shopExperience !== ShopExperience.DASHBOARD) {
      removeLocalMerchantSession();
    }
  }, [displayCountrySelectionNotification, query, shopExperience]);

  useEffect(() => {
    if (
      operationStatus.showNotification &&
      operationStatus.type === 'removeOutOfStockProduct'
    ) {
      displayNotification({
        message: removeOutOfStockProductMessage,
        variant: 'notify',
        sendToTop: true,
      });
    }
  }, [
    displayNotification,
    operationStatus.showNotification,
    operationStatus.type,
    removeOutOfStockProductMessage,
  ]);

  const handleCountryChange = useCallback(
    (code: string) => {
      const newCountry = countries.find((country) => country.code === code);
      const newLocale = `${newCountry.defaultLanguageCode}-${newCountry.code}`;

      removeLocalOrderInformation();
      dispatch(resetOrder());
      dispatch(resetPromotions());

      handleLocaleChange(newLocale);
      displayCountrySelectionNotification();
    },
    [
      countries,
      dispatch,
      handleLocaleChange,
      displayCountrySelectionNotification,
    ],
  );

  // refresh order information on mount
  useEffect(() => {
    loadLocalOrder();
  }, [loadLocalOrder, locale, marketReference]);

  // sync line item promotions with prc parameters
  usePromotionPRCs(prices);

  const isTransparentFeeEnabled = useFeatureToggleCookie(
    TRANSPARENT_FEE,
    false,
  );

  const showTransparentPricing =
    isTransparentFeeEnabled && !!pageContent.transparentPricingComponent;

  const skipShopProps = {
    message: pageContent.haveAReaderMessage,
    actionTitle: pageContent.haveAReaderActionTitle,
    actionUrl: pageContent.haveAReaderRedirectLink,
  };

  const productsOnOneLine = products.length <= 4;

  const pageHasSections = !!sections;
  const shouldShowSections = pageHasSections && !isDashboardShop;
  
  return (
    <>
      <Meta
        title={metaReference.metaTitle}
        description={metaReference.metaDescription}
        path="/product-selection"
      />
      {pageContent.blackHeaderBar && (
        <BlackHeaderBar copy={pageContent.blackHeaderBar} />
      )}
      <StyledGrid css={spacing({ bottom: 'giga', top: 'giga' })}>
        <ProductSelectionPageTitle
          title={pageContent.productListTitle}
          pageHasSections={pageHasSections}
        />
      </StyledGrid>

      <ProductGallery
        products={products}
        digitalProducts={digitalProducts}
        prices={prices}
        productCardContent={productCardContent}
        isDashboardShop={isDashboardShop}
        isCtaToCheckoutEnabled={isPsCtaToSsoEnabled || isCtaToCheckoutEnabled}
        orderOverviewTitle={orderOverviewTitle}
        addToCartText={
          isDashboardShop
            ? pageContent.addToCartText
            : pageContent.productBuyNowText
        }
        isDiscountPriceEnabled={isDiscountPriceEnabled}
        taxMessage={taxMessage}
        isBusinessAccountBundleEnabled={isBusinessAccountBundleEnabled}
      />

      {shouldShowSections &&
        sections.map((section) => (
          <Section key={section.fields.name} sectionFields={section.fields} />
        ))}

      <TransparentFeesAndFooter
        productsOnOneLine={productsOnOneLine}
        hasProductsInCart={hasProductsInCart}
      >
        {!isCtaToCheckoutEnabled && !isDashboardShop && (
          <Cart
            {...cartContent}
            productsContentful={products}
            isHidden={isBusinessAccountProposed}
            defaultTaxRate={defaultTaxRate}
          />
        )}
        <Footer
          {...skipShopProps}
          isProductSelectionPage
          footerContent={footerContent}
          showTransparentPricing={showTransparentPricing}
          hideCart={
            isCtaToCheckoutEnabled ||
            isDashboardShop ||
            isBusinessAccountProposed
          }
          localeDropdownProps={{
            countries,
            onLocaleChange: handleLocaleChange,
            onCountryChange: handleCountryChange,
            currentLocale: locale,
            countryLabel: pageContent.countrySelectLabel,
            languageLabel: pageContent.languageSelectLabel,
          }}
        />
      </TransparentFeesAndFooter>
    </>
  );
};

export const ProductSelection = withNotifications(ProductSelectionComponent);

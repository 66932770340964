import { Entry } from 'contentful';

import {
  ICountryLanguage,
  ICountryWithLocale,
} from 'shared/components/LocaleDropdown/LocaleDropdown';
import {
  ICountryFields,
  ILanguageFields,
} from 'shared/infra/contentful/contentful';
import { compose } from 'shared/utils/functional';
import { LOCALES } from 'tools/locales';

const appAvailableLocales = LOCALES as string[];

const filterLanguages = (
  languages: Entry<ILanguageFields>[],
): Entry<ILanguageFields>[] =>
  languages.filter((countryLanguage) =>
    appAvailableLocales.find((locale) =>
      locale.includes(`${countryLanguage.fields.languageCode.toLowerCase()}-`),
    ),
  );

const mapLanguages = (
  languages: Entry<ILanguageFields>[],
): ICountryLanguage[] =>
  languages.map((countryLanguage) => ({
    code: countryLanguage.fields.languageCode,
    name: countryLanguage.fields.name,
  }));

const filterCountries = (
  countries: Entry<ICountryFields>[],
): Entry<ICountryFields>[] =>
  countries.filter((country) =>
    appAvailableLocales.find((locale) =>
      locale.includes(`-${country.fields.countryCode.toUpperCase()}`),
    ),
  );

const mapCountries = (
  countries: Entry<ICountryFields>[],
): ICountryWithLocale[] =>
  countries.map((country) => ({
    code: country.fields.countryCode,
    name: country.fields.name,
    defaultLanguageCode: country.fields.defaultLanguage.fields.languageCode,
    availableLanguages: compose<Entry<ILanguageFields>[], ICountryLanguage[]>(
      mapLanguages,
      filterLanguages,
    )(country.fields.languages),
  }));

const sortCountriesAlphabetically = (countries: ICountryWithLocale[]) => {
  countries.sort((a, b) =>
    a.name.localeCompare(b.name, 'en', {
      ignorePunctuation: true,
    }),
  );
  return countries;
};

export function getEnabledShopCountriesWithLocales(
  contentfulCountries: Entry<ICountryFields>[],
): ICountryWithLocale[] {
  return compose<Entry<ICountryFields>[], ICountryWithLocale[]>(
    sortCountriesAlphabetically,
    mapCountries,
    filterCountries,
  )(contentfulCountries);
}

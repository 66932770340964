import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Truck } from '@sumup/icons';

import { getUserAgent } from 'shared/utils/user-agent';

interface BlackHeaderBarProps {
  copy: string;
}

const StyledWrapper = styled.div(
  ({ theme }) => css`
    align-items: center;
    background-color: var(--cui-bg-strong);
    color: var(--cui-fg-on-strong);
    display: flex;
    height: 52px;
    justify-content: center;
    overflow: hidden;
    width: 100vw;

    ${theme.typography.body.one};
    ${theme.mq.untilKilo} {
      ${theme.typography.body.two}
    }
  `,
);

const StyledMarquee = styled.div`
  align-items: center;
  animation: scroll-left 20s linear infinite;
  display: flex;
  justify-content: center;
  white-space: nowrap;

  @keyframes scroll-left {
    0% {
      transform: translate(100vw, 0);
    }
    100% {
      transform: translate(-100vw, 0);
    }
  }
`;

const StyledTruck = styled(Truck)`
  margin-right: 10px;
`;

function BlackHeaderContent({ copy }: Pick<BlackHeaderBarProps, 'copy'>) {
  return (
    <>
      <StyledTruck width={16} />
      <p>{copy}</p>
    </>
  );
}

export function BlackHeaderBar({ copy }: BlackHeaderBarProps) {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const { isMobile: isMobileOnUserAgent } = getUserAgent();
    setIsMobile(isMobileOnUserAgent);
  }, []);
  const hasAnimation = copy.length >= 50 && isMobile;
  return (
    <StyledWrapper>
      {hasAnimation ? (
        <StyledMarquee>
          <BlackHeaderContent copy={copy} />
        </StyledMarquee>
      ) : (
        <BlackHeaderContent copy={copy} />
      )}
    </StyledWrapper>
  );
}

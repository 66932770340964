import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, Headline } from '@sumup/circuit-ui';

import { IColumnLayoutFields } from 'shared/infra/contentful/contentful';
import { SectionColumn } from 'productSelection/components/SectionColumn';

const ColLayoutHeadline = styled(Headline)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.mega};
    text-align: center;

    ${theme.mq.mega} {
      font-size: 64px;
      line-height: 64px;
    }
  `,
);

const ColLayoutSubtitle = styled(Body)(
  ({ theme }) => css`
    text-align: center;
    margin-bottom: ${theme.spacings.giga};
  `,
);

export interface IColumnLayoutProps {
  key: string;
  columnLayoutFields: IColumnLayoutFields;
}

export const ColumnLayout: FC<IColumnLayoutProps> = ({
  key,
  columnLayoutFields,
}) => {
  return (
    <span key={key}>
      <ColLayoutHeadline as="h2" size="one">
        {columnLayoutFields.headline}
      </ColLayoutHeadline>
      {columnLayoutFields.subtitle && (
        <ColLayoutSubtitle size="one">
          {columnLayoutFields.subtitle}
        </ColLayoutSubtitle>
      )}
      {columnLayoutFields.columns &&
        columnLayoutFields.columns.map((column) => (
          <SectionColumn
            key={column.fields.headline}
            columnFields={column.fields}
          />
        ))}
    </span>
  );
};

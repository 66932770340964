import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import { Grid, useModal } from '@sumup/circuit-ui';
import { Theme } from '@sumup/design-tokens';
import { useAuth } from '@sumup/react-nanoauth';

import {
  IDigitalProductDetails,
  IProductDetails,
  IProductType,
} from 'productSelection/types/products';
import { IProductCardContent } from 'productSelection/types/content';
import { proposeBusinessAccount } from 'shared/store/order/actions';
import { useTypedSelector } from 'shared/store';
import { ICLayerProduct } from 'shared/store/order/types';
import {
  getProductsWithAllDetails,
  IProductWithPrices,
} from 'productSelection/services/product';
import { ISkuPriceData } from 'shared/infra/storefront/markets/prices';
import { ProductCard } from 'src/productSelection/components/ProductCard';
import { useAddProduct } from 'shared/hooks/orders/useAddProduct';
import { BusinessAccountModal } from 'shared/components/BusinessAccountModal';
import { dispatchGoToCheckoutEvent } from 'shared/services/tracker/events';
import { redirectToSSOSignup } from 'shared/sso/RedirectService';
import { getShopExperienceLink } from 'shared/utils/shop-experience-link';
import { useShopExperience } from 'shared/context';
import { getOrderIFrameUrl } from 'src/experiments/skip-shop/create-order/pages';

const GridWrapper = styled('ul')(
  ({ theme, cols }: { theme?: Theme; cols: number }) => css`
    display: grid;
    list-style: none;
    margin: 0;
    padding: 0 ${theme.spacings.byte} ${theme.spacings.zetta};
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ${theme.spacings.mega};
    grid-row-gap: ${theme.spacings.peta};

    ${theme.mq.mega} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      padding-bottom: calc(${theme.spacings.zetta} + ${theme.spacings.zetta});
    }

    ${theme.mq.tera} {
      grid-template-columns: repeat(${cols}, minmax(0, 1fr));
      grid-column-gap: ${theme.spacings.giga};
    }
  `,
);

const CardWrapper = styled('li')(
  () => css`
    position: relative;
    display: flex;
    flex-direction: column;
  `,
);

export interface ProductGalleryProps {
  products?: IProductDetails[];
  digitalProducts?: IDigitalProductDetails[];
  productCardContent?: IProductCardContent;
  prices: Record<string, ISkuPriceData>;
  isCtaToCheckoutEnabled?: boolean;
  orderOverviewTitle?: string;
  addToCartText?: string;
  isDiscountPriceEnabled?: boolean;
  taxMessage: string;
  isDashboardShop?: boolean;
  isBusinessAccountBundleEnabled?: boolean;
}

export const ProductGallery: FC<ProductGalleryProps> = ({
  products,
  prices,
  productCardContent,
  taxMessage,
  digitalProducts,
  isCtaToCheckoutEnabled,
  addToCartText,
  isDiscountPriceEnabled = true,
  isDashboardShop = false,
  orderOverviewTitle,
  isBusinessAccountBundleEnabled,
}) => {
  const dispatch = useDispatch();
  const { addProduct } = useAddProduct();
  const { locale, push, query } = useRouter();
  const { setModal, removeModal } = useModal();
  const source = useShopExperience();
  const { authRedirect, authState } = useAuth();

  const {
    operationStatus,
    products: orderProducts,
    isBusinessAccountSelected,
  } = useTypedSelector((state) => state.order);

  const catalogGalleryLength = products.length + digitalProducts.length;

  const proceedToCheckout = useCallback(
    (orderId: string) => {
      dispatchGoToCheckoutEvent({
        shopExperience: source,
      });
      const requestParams = {
        ...{
          iframe_uri: getOrderIFrameUrl(orderId, locale),
          iframe_title: orderOverviewTitle,
        },
      };

      redirectToSSOSignup({
        locale,
        authRedirect,
        authState,
        push,
        query,
        redirectPathname: getShopExperienceLink(`/checkout/address`),
        source,
        requestParams,
      });
    },
    [authRedirect, authState, locale, push, query, source, orderOverviewTitle],
  );

  const addProductToCart = useCallback(
    (
      sku: string,
      type: IProductType,
      contentfulId: string,
      quantity = 1,
      isBusinessAccountSelected: boolean,
    ): void => {
      const priceBySku = Object.values(prices)?.find(
        (priceByContentfulId) => priceByContentfulId.skuCode === sku[0],
      );
      const applicableCouponCode = priceBySku?.appliedCouponCode || '';

      addProduct({
        sku: sku,
        type: type,
        quantity,
        couponCode: applicableCouponCode,
        locale: locale,
        contentfulId,
        allowOnlyOne: !isDashboardShop,
        businessAccount: {
          selected: isBusinessAccountSelected,
          enabled: isBusinessAccountBundleEnabled,
        },
      });
    },
    [
      prices,
      isDashboardShop,
      locale,
      isBusinessAccountBundleEnabled,
      addProduct,
    ],
  );

  const businessAccountModalChildren = useCallback(
    (
      sku: string,
      type: IProductType,
      contentfulId: string,
      quantity: number,
    ) => {
      return (
        <BusinessAccountModal
          productId={contentfulId}
          //isBusinessAccountSelected needs to be passed through because setModal is preventing
          //the component state to be updated (memoized function).
          onProceedClick={(isBusinessAccountSelected: boolean) => {
            addProductToCart(
              sku,
              type,
              contentfulId,
              quantity,
              isBusinessAccountSelected,
            );
          }}
          onFinishProceed={(orderId: string) => {
            removeModal();
            proceedToCheckout(orderId);
          }}
        />
      );
    },
    [addProductToCart, removeModal, proceedToCheckout],
  );

  const openBusinessAccountModal = useCallback(
    (
      sku: string,
      type: IProductType,
      contentfulId: string,
      quantity = 1,
    ): void => {
      const modal = {
        children: businessAccountModalChildren(
          sku,
          type,
          contentfulId,
          quantity,
        ),
        preventClose: true,
      };

      setModal(modal);
    },
    [setModal, businessAccountModalChildren],
  );

  const handleProductSelection = useCallback(
    (
      sku: string,
      type: IProductType,
      contentfulId: string,
      quantity = 1,
    ): void => {
      if (isBusinessAccountBundleEnabled) {
        dispatch(proposeBusinessAccount(true));
        openBusinessAccountModal(sku, type, contentfulId, quantity);
      } else {
        addProductToCart(
          sku,
          type,
          contentfulId,
          quantity,
          isBusinessAccountSelected,
        );
      }
    },
    [
      dispatch,
      addProductToCart,
      isBusinessAccountBundleEnabled,
      isBusinessAccountSelected,
      openBusinessAccountModal,
    ],
  );

  const handleSelect = (
    sku: string,
    type: IProductType,
    contentfulId: string,
    quantity?: number,
  ) => {
    if (!isDashboardShop || (isDashboardShop && !operationStatus.loading)) {
      return handleProductSelection(sku, type, contentfulId, quantity);
    }
  };

  const isAddingProduct = useCallback(
    (productReference) =>
      productReference === operationStatus.productReference &&
      operationStatus.loading,
    [operationStatus],
  );

  const hasProductInstallmentsOnPage = useMemo(
    () => products.some((p) => p.numberOfInstallments > 1),
    [products],
  );
  
  const productList = useMemo(
    () =>
      getProductsWithAllDetails(
        products,
        prices,
        locale,
        taxMessage,
      ) as IProductWithPrices[],
    [locale, prices, products, taxMessage],
  );

  const productsInARowForDesktop = useMemo(() => {
    if (catalogGalleryLength < 5) {
      return catalogGalleryLength;
    }
    return 3;
  }, [catalogGalleryLength]);

  return (
    <Grid>
      <GridWrapper cols={productsInARowForDesktop}>
        {productList.map((product) => (
          <CardWrapper key={product.id}>
            <ProductCard
              {...product}
              formattedTotalAmount={product.formattedAmount}
              productsCount={catalogGalleryLength}
              isDashboardShop={isDashboardShop}
              taxMessage={product.formattedTaxMessage}
              formattedUnitAmount={product.formattedCompareAmount}
              content={productCardContent}
              isSelected={
                orderProducts.some(
                  (item: ICLayerProduct) => item.reference === product.id,
                ) && !isCtaToCheckoutEnabled
              }
              isLoading={isAddingProduct(product.id)}
              onSelect={handleSelect}
              isInstallmentsLayout={hasProductInstallmentsOnPage}
              isCtaToCheckoutEnabled={isCtaToCheckoutEnabled}
              shortBenefits={product.shortBenefits}
              addToCartText={addToCartText}
              isDiscountPriceEnabled={isDiscountPriceEnabled}
            />
          </CardWrapper>
        ))}
        {digitalProducts.map((digitalProduct) => (
          <CardWrapper key={digitalProduct.id}>
            <ProductCard
              {...digitalProduct}
              isDigitalProduct
              amountFloat={0}
              content={{
                ...productCardContent,
                discountBadgeText: digitalProduct.discountBadgeText,
              }}
              productsCount={catalogGalleryLength}
              isCtaToCheckoutEnabled={isCtaToCheckoutEnabled}
              addToCartText={addToCartText}
              stockQuantity={null}
            />
          </CardWrapper>
        ))}
      </GridWrapper>
    </Grid>
  );
};

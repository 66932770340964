import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Headline } from '@sumup/circuit-ui';
import { FC } from 'react';

type Props = {
  title: string;
  pageHasSections?: boolean;
};

const StyledHeadline = styled(Headline)<{ pageHasSections?: boolean }>(
  ({ theme, pageHasSections }) => css`
    margin-bottom: ${theme.spacings.tera};
    text-align: center;

    ${theme.mq.giga} {
      // TODO: update once the circuit-ui is updated
      font-size: ${pageHasSections ? `96px` : `64px`};
      line-height: ${pageHasSections ? `96px` : `56px`};
      margin-top: ${theme.spacings.peta};
      margin-bottom: ${pageHasSections ? `74px` : `${theme.spacings.zetta}`};
    }
  `,
);

export const ProductSelectionPageTitle: FC<Props> = ({
  title,
  pageHasSections,
}) => {
  return (
    <StyledHeadline as="h1" pageHasSections={pageHasSections}>
      {title}
    </StyledHeadline>
  );
};

import { useEffect, useState, useCallback } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, Headline, List } from '@sumup/circuit-ui';
import { useDispatch } from 'react-redux';

import { BusinessAccountSelector } from '../BusinessAccountSelector';
import { CARD_OPTIONS } from '../BusinessAccountSelector/BusinessAccountSelector';

import { useTypedSelector } from 'shared/store';
import { selectBusinessAccount } from 'shared/store/order/actions';
import {
  dispatchBusinessAccountLearnMoreCloseEvent,
  dispatchBusinessAccountLearnMoreOpenEvent,
} from 'shared/services/tracker/events';
import { Button } from 'src/experiments/odl/components/Button';

interface BusinessAccountModalProps {
  productId?: string;
  onProceedClick?: Function;
  onFinishProceed?: Function;
}

const StyledWrapper = styled.div`
  max-width: 488px;
  padding: 8px;
`;

const StyledParagraph = styled.p(
  ({ theme }) => css`
    margin-top: ${theme.spacings.byte};
    margin-bottom: ${theme.spacings.byte};
  `,
);

const StyledDetails = styled.details(
  ({ theme }) => css`
    & > summary::after {
      content: url('https://circuit.sumup.com/icons/v2/chevron_down_16.svg');
      position: relative;
      top: 2px;
      left: ${theme.spacings.bit};
    }

    &[open] > summary::after {
      content: url('https://circuit.sumup.com/icons/v2/chevron_up_16.svg');
    }
  `,
);

const StyledSummary = styled.summary`
  text-decoration: underline;
  display: inline;
  cursor: pointer;
`;

const ConditionsHeadline = styled(Headline)(
  ({ theme }) => css`
    margin-top: ${theme.spacings.giga};
    margin-bottom: ${theme.spacings.mega};
  `,
);

const ConditionsList = styled(List)`
  li {
    margin-bottom: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export function BusinessAccountModal({
  productId,
  onProceedClick = () => {},
  onFinishProceed = () => {},
}: BusinessAccountModalProps) {
  const dispatch = useDispatch();
  const [isClickedProceed, setClickedProceed] = useState(false);
  const [isLearnMoreOpen, setIsLearnMoreOpen] = useState(false);
  const { operationStatus, isBusinessAccountSelected, orderDetails } =
    useTypedSelector((state) => state.order);
  const isAddingProduct = useCallback(
    () =>
      productId === operationStatus.productReference && operationStatus.loading,
    [operationStatus, productId],
  );
  const handleSelectOption = (selectedValue: string) => {
    dispatch(
      selectBusinessAccount(selectedValue === CARD_OPTIONS.withBusinessAccount),
    );
  };
  const handleProceedClick = () => {
    setClickedProceed(true);
    onProceedClick(isBusinessAccountSelected);
  };
  const handleLearnMoreClick = () => {
    if (!isLearnMoreOpen) {
      // when isLearnMoreOpen starts out false, that means this click
      // will open the details, thus we call the open event
      dispatchBusinessAccountLearnMoreOpenEvent();
    } else {
      dispatchBusinessAccountLearnMoreCloseEvent();
    }
    setIsLearnMoreOpen(!isLearnMoreOpen);
  };

  useEffect(() => {
    if (!isAddingProduct() && isClickedProceed) {
      onFinishProceed(orderDetails.id);
    }
  }, [
    isClickedProceed,
    isAddingProduct,
    onFinishProceed,
    orderDetails.id,
    productId,
    operationStatus,
  ]);

  return (
    <StyledWrapper>
      <Headline size="two" as="h2">
        Select your preferred payment account
      </Headline>
      <StyledParagraph>
        By selecting a free Business Account, you&apos;ll get a 12% discount on
        your card reader fee, which you can extend for months just by using your
        SumUp Card.
      </StyledParagraph>
      <StyledDetails>
        <StyledSummary onClick={handleLearnMoreClick}>
          See discount details
        </StyledSummary>
        <ConditionsHeadline size="four" as="h3">
          To extend your discount to future months, you&apos;ll need to:
        </ConditionsHeadline>
        <ConditionsList size="two">
          <li>
            Make{' '}
            <Body size="two" variant="highlight">
              6+ purchases
            </Body>{' '}
            a month with your SumUp Mastercard
          </li>
          <li>
            Each purchase should be worth at least{' '}
            <Body size="two" variant="highlight">
              £10
            </Body>
          </li>
          <li>
            Both{' '}
            <Body size="two" variant="highlight">
              online and in-store
            </Body>{' '}
            purchases count
          </li>
        </ConditionsList>
      </StyledDetails>
      <BusinessAccountSelector
        selectedCard={
          isBusinessAccountSelected
            ? CARD_OPTIONS.withBusinessAccount
            : CARD_OPTIONS.withoutBusinessAccount
        }
        setSelectedCard={handleSelectOption}
      />
      <ButtonWrapper>
        <Button
          data-testid="ba-checkout-button"
          variant="primary"
          isLoading={isAddingProduct()}
          loadingLabel="Loading..."
          onClick={handleProceedClick}
        >
          Proceed to checkout
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  );
}

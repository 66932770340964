import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Grid } from 'shared/components/Grid';
import { IColumnLayoutFields } from 'shared/infra/contentful/contentful';
import { ColumnLayout } from 'src/productSelection/containers/ColumnLayout';

const SectionsGrid = styled(Grid)<{ children: ReactNode }>`
  ${({ theme }) => css`
    padding: 0;
    width: 100%;
    margin-bottom: ${theme.spacings.exa};
    margin-top: ${theme.spacings.exa};

    ${theme.mq.mega} {
      margin-bottom: ${theme.spacings.zetta};
    }
  `}
`;

export interface ISectionProps {
  key: string;
  sectionFields: IColumnLayoutFields;
}

export const Section: FC<ISectionProps> = ({ key, sectionFields }) => {
  return (
    <SectionsGrid>
      <ColumnLayout key={key} columnLayoutFields={sectionFields} />
    </SectionsGrid>
  );
};

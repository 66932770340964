import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, Col, Headline, Image, spacing } from '@sumup/circuit-ui';

import { IColumnFields } from 'shared/infra/contentful/contentful';
import { convertImageToPng } from 'shared/services/imageUrl';
import { dispatchSkipShopEvent } from 'shared/services/tracker/events';
import { Button } from 'src/experiments/odl/components/Button';

const StyledImageWrapper = styled('div')(
  ({ theme }) => css`
    background-color: var(--cui-bg-subtle);
    border-radius: ${theme.borderRadius.mega};
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 206px;
    max-height: 340px;
    width: 100%;
    text-align: center;
    overflow: hidden;
    margin-bottom: ${theme.spacings.mega};
    margin-top: ${theme.spacings.giga};

    img {
      max-height: 100%;
      max-width: 100%;
      overflow: hidden;
    }

    ${theme.mq.mega} {
      min-height: none;
      margin-bottom: ${theme.spacings.giga};
      margin-top: ${theme.spacings.zetta};
    }
  `,
);

const Column = styled(Col)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.zetta};

    ${theme.mq.mega} {
      margin-bottom: 140px;
    }
  `,
);

const ColumnHeadline = styled(Headline)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.mega};
  `,
);

const ColumnContent = styled(Body)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.mega};
  `,
);

export interface ISectionColumnProps {
  key: string;
  columnFields: IColumnFields;
}

export const SectionColumn: FC<ISectionColumnProps> = ({
  key,
  columnFields,
}) => {
  const trackSectionCtaClick = (label: string) => {
    dispatchSkipShopEvent({ label });
  };

  return (
    <Column
      key={key}
      span={{ untilKilo: 12, kilo: 12, mega: 6 }}
      css={spacing({ bottom: 'zetta' })}
    >
      {columnFields.image && (
        <StyledImageWrapper>
          <Image
            src={convertImageToPng(columnFields.image.fields.file.url)}
            alt={columnFields.image.fields.description || ''}
          />
        </StyledImageWrapper>
      )}
      <ColumnHeadline as="h3">{columnFields.headline}</ColumnHeadline>
      {columnFields.content && (
        <ColumnContent>{columnFields.content}</ColumnContent>
      )}
      {columnFields.primaryCtaLabel && (
        <Button
          variant="primary"
          target="_blank"
          onClick={() =>
            trackSectionCtaClick(columnFields.primaryCtaTrackingId)
          }
          href={columnFields.primaryCtaUrl}
        >
          {columnFields.primaryCtaLabel}
        </Button>
      )}
      {columnFields.secondaryCtaLabel && (
        <Button
          variant="secondary"
          target="_blank"
          onClick={() =>
            trackSectionCtaClick(columnFields.secondaryCtaTrackingId)
          }
          css={spacing({ left: 'kilo' })}
          href={columnFields.secondaryCtaUrl}
        >
          {columnFields.secondaryCtaLabel}
        </Button>
      )}
    </Column>
  );
};
